<template style="">
  <div style="">
    <DataTable
      :value="values"
      :class="
        'datatable-custom datatable-custom-edit mt-3 data-table-comparativo ' +
          collapse
      "
      :style="'width: 3000px' + '; padding-right: 16px;'"
    >
      <Column field="id" header="ID">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.id }}
          </div>
        </template>
      </Column>
      <Column field="retail" header="Retails">
        <template #body="slotProps">
          <div>
            <Chip :label="slotProps.data.retail" class="chip-custom"></Chip>
          </div>
        </template>
      </Column>
      <Column field="areas" header="Areas">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.area }}
          </div>
        </template>
      </Column>

      <Column field="divisions" header="Divisions">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.division }}
          </div>
        </template>
      </Column>
      <Column field="categorias" header="Categorias">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.categoria }}
          </div>
        </template>
      </Column>
      <Column field="Producto" header="Producto">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.producto }}
          </div>
        </template>
      </Column>
      <Column field="brands" header="Brands">
        <template #body="slotProps">
          <div>
            <Chip :label="slotProps.data.brand" class="chip-custom"></Chip>
          </div>
        </template>
      </Column>
      <Column field="feature_1" header="Feature 1">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.feature_1 }}
          </div>
        </template>
      </Column>
      <Column field="feature_2" header="Feature 2">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.feature_2 }}
          </div>
        </template>
      </Column>
      <Column field="feature_3" header="Feature 3">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.feature_3 }}
          </div>
        </template>
      </Column>
      <Column field="feature_4" header="Feature 4">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.feature_4 }}
          </div>
        </template>
      </Column>
      <Column field="feature_5" header="Feature 5">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.feature_5 }}
          </div>
        </template>
      </Column>
      <Column field="price_lista" header="Precio Lista">
        <template #body="slotProps">
          <div v-if="slotProps.data.precio_de_lista != 0">
            {{ slotProps.data.precio_de_lista }}
          </div>
          <div v-else>0,00</div>
        </template>
      </Column>
      <Column field="precio_extra" header="Precio Extra">
        <template #body="slotProps">
          <div v-if="slotProps.data.precio_extra != 0">
            {{ slotProps.data.precio_extra }}
          </div>
          <div v-else>0,00</div>
        </template>
      </Column>
      <Column field="precio_oferta" header="Precio Oferta">
        <template #body="slotProps">
          <div v-if="slotProps.data.precio_oferta != 0">
            {{ slotProps.data.precio_oferta }}
          </div>
          <div v-else>0,00</div>
        </template>
      </Column>
      <Column field="promo" header="Promo">
        <template #body="slotProps">
          <div v-if="slotProps.data.promo != 0">
            {{ slotProps.data.promo }}
          </div>
          <div v-else>0,00</div>
        </template>
      </Column>
      
      <Column field="stock" header="Posición">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.posición }}
          </div>
        </template>
      </Column>
      <Column field="fecha" header="Fecha">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.fecha }}
          </div>
        </template>
      </Column>
      <Column field="enlace" header="Enlace">
        <template #body="slotProps">
          <div>
            <a :href="slotProps.data.enlace" target="_blank" class="link"
              >Ver en sitio</a
            >
          </div>
        </template>
      </Column>
      <Column field="stock" header="Stock" v-if="checkOptions('stock') == true">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.stock }}
          </div>
        </template>
      </Column>

      <Column field="sku" header="Sku" v-if="checkOptions('sku') == true">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.sku }}
          </div>
        </template>
      </Column>

      <Column
        field="unidad"
        header="Unidad"
        v-if="checkOptions('unidad') == true"
      >
        <template #body="slotProps">
          <div>
            {{ slotProps.data.unidad }}
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
//Components primevue
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import ToggleButton from "primevue/togglebutton";

import AutoComplete from "primevue/autocomplete";

//Services
import { ProductsService } from "../../services/Taxonomia/ProductsService";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import { FeatureService } from "../../services/FeatureService";
import { BrandService } from "../../services/BrandService";
import UsersServices from "../../services/UsersServices";

export default {
  components: {
    DataTable,
    Dropdown,
    InputText,
    Column,
    MultiSelect,
    Chip,
    Toolbar,
    Button,
    Checkbox,
    SplitButton,
    ToggleButton,
    AutoComplete,
  },

  props: ["values", "collapse", "options"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    checkOptions(option) {
      console.log(this.options);
      if (this.options.length == 0) {
        return true;
      } else {
        var optionSelect = this.options.filter((item) => item == option);
        console.log(optionSelect);

        if (optionSelect.length == 0) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
};
</script>
