<template>
  <div>
    <div style="display: flex" class="p-mt-4">
      {{ propsToVar }}
      <Dropdown
        v-if="paisList"
        class="custom-dropdown mr-2"
        :style="'max-width:' + width"
        v-model="paisSelect"
        :options="paisList"
        @input="$emit('InputPais', paisSelect)"
        @hide="hidePais"
        optionLabel="name"
        placeholder="País"
        :disabled="optDisabled.pais"
      />

      <MultiSelect
        v-if="chipsRetail"
        v-model="selectedRetail"
        class="custom-multiselect mr-2"
        :style="'max-width:' + width"
        :options="retail"
        optionLabel="name"
        placeholder="Retail"
        display="chip"
        @show="showRetail"
        @hide="hideRetail"
        :disabled="optDisabled.retail"
      />
      <MultiSelect
        v-if="type != 'comparativo' && type != 'historico' && chipsCategoria"
        v-model="selectedCategoria"
        class="custom-multiselect header-closed mr-2"
        :style="'max-width:' + width"
        :options="categoria"
        optionLabel="name"
        placeholder="Categoria"
        display="chip"
        @show="$emit('ShowCategoria', selectedCategoria)"
        @hide="hideCategoria"
        :disabled="optDisabled.categoria"
      />

      <AutoComplete
        v-if="type == 'comparativo' || type == 'historico'"
        v-model="selectedArea"
        :suggestions="area"
        :disabled="optDisabled.area"
        :style="'max-width:' + width"
        :dropdown="true"
        @complete="searchArea($event)"
        @item-select="selectArea($event)"
        field="name"
        placeholder="Area"
        class="custom-multiselect custom-auto-complete disabled no-border"
      />

      <AutoComplete
        v-if="type == 'comparativo' || type == 'historico'"
        v-model="selectedDivision"
        :suggestions="division"
        :disabled="optDisabled.division"
        :style="'max-width:' + width"
        :dropdown="true"
        @complete="searchDivision($event)"
        @item-select="selectDivision($event)"
        field="name"
        placeholder="Division"
        class="custom-multiselect custom-auto-complete disabled no-border"
      />

      <AutoComplete
        v-if="type == 'comparativo' || type == 'historico'"
        v-model="selectedCategoria_abm"
        :suggestions="categoria_abm"
        :dropdown="true"
        :disabled="optDisabled.categoria"
        :style="'max-width:' + width"
        @complete="searchCategoria($event)"
        field="name"
        placeholder="Categoria"
        class="custom-multiselect custom-auto-complete disabled no-border"
      />

      <AutoComplete
        v-if="type == 'comparativo' || type == 'historico'"
        v-model="selectedBrand_abm"
        :suggestions="brand_abm"
        :disabled="false"
        :dropdown="true"
        :style="'min-width:' + width"
        multiple
        @complete="searchBrand($event)"
        field="name"
        placeholder="Brands"
        class="custom-multiselect custom-auto-complete disabled fix-button"
      />

      <Dropdown
        v-if="type == 'comparativo'"
        v-model="selectedPrice"
        class="custom-dropdown"
        :style="'max-width:' + width"
        :options="priceList"
        optionLabel="name"
        placeholder="Preço"
      />
    </div>
    <div
      v-if="type == 'comparativo' || type == 'historico'"
      class="mt-2"
      style="justify-content: flex-end; display:flex"
    >
      <div
        v-if="type == 'historico'"
        style="background-color: white; width: 100%; display: flex; justify-content: center; margin-right: 10px; box-shadow: 0px 4px 7px #d6d6d6 !important;"
      >
        <div style="margin-right: 20px; margin-left: 20px; align-self: center;">
          <Checkbox
            id="unidad"
            inputId="unidad"
            name="unidad"
            value="unidad"
            v-model="options"
          />
          <label for="unidad" style="margin-bottom: 0px; margin-left: 8px"
            >Unidad</label
          >
        </div>
        <div style="margin-right: 20px; margin-left: 20px; align-self: center;">
          <Checkbox
            id="sku"
            inputId="sku"
            name="sku"
            value="sku"
            v-model="options"
          />
          <label for="sku" style="margin-bottom: 0px; margin-left: 8px"
            >Sku</label
          >
        </div>
        <div style="margin-right: 20px; margin-left: 20px; align-self: center;">
          <Checkbox
            id="stock"
            inputId="stock"
            name="stock"
            value="stock"
            v-model="options"
          />
          <label for="stock" style="margin-bottom: 0px; margin-left: 8px"
            >Stock</label
          >
        </div>
      </div>
      <Calendar
        v-model="dateHoy"
        v-if="type == 'comparativo'"
        class="custom-multiselect custom-auto-complete custom-calendar"
        placeholder="Date"
        style="max-width: 200px"
        :showIcon="true"
        :disabledDates="invalidDates"
        @date-select="compDatePickerSelect"
        :maxDate="maxDateValue"
      />
      <Calendar
        v-model="dateDesde"
        v-if="type == 'historico'"
        class="custom-multiselect custom-auto-complete custom-calendar"
        placeholder="Fecha desde"
        style="max-width: 200px"
        :showIcon="true"
        :disabledDates="invalidDates"
        @date-select="dateDesdeSelect"
        :maxDate="maxDateValue"
      />

      <Calendar
        v-model="dateHasta"
        v-if="type == 'historico'"
        class="custom-multiselect custom-auto-complete custom-calendar"
        placeholder="Fecha hasta"
        style="max-width: 200px"
        :showIcon="true"
        :disabledDates="invalidDates"
        :disabled="datePicker2Disabled"
        @date-select="dateHastaSelect"
        :maxDate="maxDateValue"
      />
      <Button
        class="button-custom mr-2"
        style="width: 50%; max-width: 200px"
        :label="btnLeftLabel"
        @click="getQuery"
      ></Button>
      <Button
        class="button-custom-limpiar"
        style="width: 50%; max-width: 200px"
        label="LIMPIAR"
        v-on:click="clearCampos"
      ></Button>
    </div>
    <div
      v-if="type == 'comparativo' || type == 'historico'"
      class="mt-2"
      style="justify-content: flex-end; display:flex"
    >
      <Button
        class="button-custom mr-2"
        style="width: 50%; max-width: 180.23px"
        label="Exportar"
      ></Button>
      <Button
        class="button-custom"
        style="width: 50%; max-width: 180.23px"
        label="Limpiar Tabla"
      ></Button>
    </div>
  </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import { AreaService } from "../../services/AreaService";
import { DivisionService } from "../../services/DivisionService";
import { OldBrandService } from "../../services/Taxonomia/OldBrandService";
import { BrandService } from "../../services/BrandService";
import Calendar from "primevue/calendar";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import Checkbox from "primevue/checkbox";

export default {
  components: {
    Dropdown,
    MultiSelect,
    Button,
    AutoComplete,
    Calendar,
    Checkbox,
  },
  data() {
    return {
      selectedDivision: null,
      selectedArea: null,
      selectedBrand: null,
      selectedCategoria: null,
      paisSelect: null,
      selectedRetail: null,
      selectedRetailShow: null,
      selectedCategoria_abm: null,
      selectedBrand_abm: null,

      optDisabled: {
        pais: false,
        retail: true,
        area: false,
        division: true,
        categoria: true,
      },

      datePicker2Disabled: true,

      retail: [],
      area: [],
      division: [],
      categoria: [],

      datos: [],

      brand: [],

      brand_abm: [],

      datePicker: null,

      categoria_abm: [],

      selectedPrice: null,

      dateHoy: null,
      dateDesde: null,
      dateHasta: null,

      maxDateValue: null,

      priceList: [
        { name: "Precio Lista", id: 1, method: "amount" },
        { name: "Precio Oferta", id: 2, method: "amount_discount" },
        { name: "Precio Extra", id: 3, method: "amount_extra" },
      ],

      options: [],

      invalidDates: null,
      disabled: true,
    };
  },

  props: [
    "paisList",
    "chipsRetail",
    "chipsArea",
    "chipsDivision",
    "chipsCategoria",
    "chipsBrand",
    "width",
    "type",
    "chipsCategoria_Abm",
    "btnLeftLabel",
  ],

  computed: {
    propsToVar() {
      if (this.chipsRetail != null) {
        this.retail = this.chipsRetail;
      }

      if (this.chipsArea) {
        this.area = this.chipsArea;
      }

      if (this.chipsDivision) {
        this.division = this.chipsDivision;
      }

      if (this.chipsCategoria) {
        this.categoria = this.chipsCategoria;
      }

      if (this.chipsBrand) {
        this.brand = this.chipsBrand;
      }

      if (this.chipsCategoria_Abm) {
        this.categoria_abm = this.chipsCategoria_Abm;
      }
    },
  },

  created() {
    let today = new Date();

    var dia = 0;
    this.invalidDates = [];

    this.dateHoy = new Date();
    this.maxDateValue = new Date();

    if (this.type == "historico" || this.type == "comparativo") {
      BrandService.getAll()
        .then((result) => {
          this.brand_abm = result.data.data;
        })
        .catch((err) => {});
    }
  },

  methods: {
    compDatePickerSelect(event) {
      var day = event.getUTCDate();
      var month = event.getUTCMonth();

      var dayActual = new Date().getUTCDate();
      var monthActual = new Date().getUTCMonth();

      var diff = dayActual - day;
      if (diff > 5 || monthActual != month) {
        this.$store.dispatch("alert_error", {
          message:
            "¿Aconsejamos no seleccionar fechas con diferencias de un máximo de 5 días?",
          time: 3500,
        });
      } else {
      }
    },

    searchCountry(event) {
      if (this.paisSelect && this.selectedRetail && this.selectedCategoria) {
        var country = [this.paisSelect.country];
        var retail = [];
        var categoria = [];

        this.selectedRetail.map((item) => {
          retail.push(item.retail);
        });
        this.selectedCategoria.map((item) => {
          categoria.push(item.category);
        });

        this.datos = [];

        OldBrandService.getAutoComplete(retail, country, categoria, event.query)
          .then((result) => {
            result.data.map((item) => {
              var newObj = { name: item.brand, code: item.brand };

              this.datos.push(newObj);
            });
            this.brand = this.datos;
          })
          .catch((err) => {});
      }
    },

    hidePais() {
      this.$emit("ShowPais", this.paisSelect); //Function call back e passa os valores selecionados
      if (this.paisSelect != null) {
        this.clear();
        this.optDisabled.retail = false;
      } else {
        this.optDisabled.retail = true;
      }
    },

    showRetail() {
      this.$emit("ShowRetail", this.selectedRetail); //Function call back e passa os valores selecionados
      this.selectedRetailShow = this.selectedRetail;
    },

    hideRetail() {
      this.$emit("HideRetail", this.selectedRetail); //Function call back e passa os valores selecionados

      if (this.selectedRetail != null) {
        if (this.selectedRetailShow != this.selectedRetail) {
          this.selectedCategoria = null;
          this.selectedBrand = null;

          if (this.selectedRetail.length) {
            this.categoria = [];
            this.brand = [];
          }
        }

        if (this.type == "comparativo") {
        } else {
          this.optDisabled.categoria = false;
        }
      } else {
        if (this.type == "comparativo") {
        } else {
          this.optDisabled.categoria = true;
        }
      }
    },

    searchArea(event) {
      var name = event.query;
      this.selectedDivision = [];
      this.selectedCategoria_abm = [];

      AreaService.getAutocomplete(name)
        .then((result) => {
          this.area = result.data;
        })
        .catch((err) => {});
    },

    selectArea(event) {
      this.selectedDivision = [];

      this.optDisabled.division = false;

      this.selectedCategoria_abm = [];

      this.optDisabled.categoria = true;
    },

    searchDivision(event) {
      var name = event.query;
      var area_id = this.selectedArea.id;

      this.selectedCategoria_abm = [];
      this.optDisabled.categoria = true;

      if (area_id != null) {
        DivisionService.getAutocomplete(name, area_id)
          .then((result) => {
            this.division = result.data;
          })
          .catch((err) => {});
      }
    },

    selectDivision(event) {
      this.selectedCategoria_abm = [];

      this.optDisabled.categoria = false;
    },

    searchCategoria(event) {
      var name = event.query;
      var division_id = this.selectedDivision.id;

      if (division_id != null) {
        CategoriaServiceABM.getAutocomplete(name, division_id)
          .then((result) => {
            this.categoria_abm = result.data;
          })
          .catch((err) => {});
      }
    },

    searchBrand(event) {
      var name = event.query;

      BrandService.getAutocomplete(name)
        .then((result) => {
          this.brand_abm = result.data;
        })
        .catch((err) => {});
    },

    getQuery() {
      var query = [];

      //Country
      if (this.paisSelect != null) {
        query.country = this.paisSelect.country;
      }

      //Retail
      if (this.selectedRetail != null) {
        query.retail = this.selectedRetail;
      }

      //Category
      if (this.selectedCategoria != null) {
        query.category = this.selectedCategoria;
      }

      //Brand ou category_abm
      if (this.selectedBrand != null) {
        query.brand = this.selectedBrand;
      }

      if (this.selectedCategoria_abm != null) {
        query.category_abm = this.selectedCategoria_abm;
      }

      if (this.type == "historico") {
        query.date = { desde: this.dateDesde, hasta: this.dateHasta };
      } else {
        query.date = this.dateHoy;
      }
      if (this.selectedBrand_abm != null) {
        query.brand = this.selectedBrand_abm;
      }

      if (this.selectedPrice != null && this.type == "comparativo") {
        query.price = this.selectedPrice;
      }

      query.options = this.options;

      this.$emit("getObtener", query);
    },

    clear() {
      this.selectedRetail = null;

      if (this.type != "comparativo") {
        this.selectedDivision = null;
        this.selectedArea = null;
        this.selectedBrand = null;
        this.selectedCategoria = null;
        this.retail = [];
        this.area = [];
        this.division = [];
        this.categoria = [];

        this.optDisabled = {
          pais: false,
          retail: true,
          area: false,
          division: true,
          categoria: true,
        };
      }
    },

    clearCampos() {
      this.$emit("ClickClear"); //Function call back
      this.selectedCategoria = null;
      this.selectedRetail = null;
      this.selectedBrand = null;
      this.paisSelect = null;
      this.selectedArea = null;
      this.selectedDivision = null;

      if (this.chipsRetail) {
        this.retail = [];
      }

      if (this.chipsArea) {
        this.area = [];
      }

      if (this.chipsDivision) {
        this.division = [];
      }

      if (this.chipsCategoria) {
        this.categoria = [];
      }

      if (this.chipsBrand) {
        this.brand = [];
      }

      this.optDisabled = {
        pais: false,
        retail: true,
        area: false,
        division: true,
        categoria: true,
      };

      this.disabled = true;
    },

    disabledDaysController() {
      return [1];
    },

    dateHastaSelect(event) {
      var firstDate = this.dateDesde.getUTCDate();
      var lastDate = event.getUTCDate();

      var diff = lastDate - firstDate;

      if (diff > 5) {
        this.$store.dispatch("alert_error", {
          message:
            "Aconsejamos no seleccionar fechas con una diferencia máxima de 5 días",
          time: 3000,
        });
      }
    },

    dateDesdeSelect(event) {
      this.datePicker2Disabled = false;
    },
  },
};
</script>
<style lang=""></style>
