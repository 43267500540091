<template style="">
  <div style="">
    {{ data }}
    <div>
      <DataTable
        :value="values"
        :class="
          'datatable-custom datatable-custom-edit mt-3 data-table-comparativo ' +
          collapse
        "
        :style="'width: 3000px' + '; padding-right: 16px;'"
      >
        <Column field="id" header="ID" headerStyle="width: 4em">
          <template #body="slotProps">
            {{ slotProps.data.id }}
          </template>
        </Column>

        <Column field="area" header="Área">
          <template #body="slotProps">
            <Chip :label="slotProps.data.area" class="chip-custom"></Chip>
          </template>
        </Column>

        <Column field="division" header="División">
          <template #body="slotProps">
            <Chip :label="slotProps.data.division" class="chip-custom"></Chip>
          </template>
        </Column>

        <Column field="categoria" header="Categoria">
          <template #body="slotProps">
            <Chip :label="slotProps.data.categoria" class="chip-custom"></Chip>
          </template>
        </Column>

        <Column field="producto" header="Producto">
          <template #body="slotProps">
            {{ slotProps.data.producto }}
          </template>
        </Column>

        <Column field="brand" header="Brand">
          <template #body="slotProps">
            {{ slotProps.data.brand }}
          </template>
        </Column>

        <Column field="feature_1" header="Feature 1">
          <template #body="slotProps">
            <div v-if="slotProps.data.feature_1">
              {{ slotProps.data.feature_1 }}
            </div>
            <div v-else></div>
          </template>
        </Column>

        <Column field="feature_2" header="Feature 2">
          <template #body="slotProps">
            <div v-if="slotProps.data.feature_2">
              {{ slotProps.data.feature_2 }}
            </div>
            <div v-else></div>
          </template>
        </Column>

        <Column field="feature_3" header="Feature 3">
          <template #body="slotProps">
            <div v-if="slotProps.data.feature_3">
              {{ slotProps.data.feature_3 }}
            </div>
            <div v-else></div>
          </template>
        </Column>

        <Column field="feature_4" header="Feature 4">
          <template #body="slotProps">
            <div v-if="slotProps.data.feature_4">
              {{ slotProps.data.feature_4 }}
            </div>
            <div v-else></div>
          </template>
        </Column>

        <Column field="feature_5" header="Feature 5">
          <template #body="slotProps">
            <div v-if="slotProps.data.feature_5">
              {{ slotProps.data.feature_5 }}
            </div>
            <div v-else></div>
          </template>
        </Column>

        <div v-for="field in separator(pricesRetails)" :key="field">
          <Column :field="field" :header="field">
            <template #body="slotProps">
              {{selector(slotProps.data.products, field, price)}}
            </template>
          </Column>
        </div>
      </DataTable>
    </div>
  </div>
</template>
<script>
//Components primevue
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import ToggleButton from "primevue/togglebutton";

import AutoComplete from "primevue/autocomplete";

//Services
import { ProductsService } from "../../services/Taxonomia/ProductsService";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import { FeatureService } from "../../services/FeatureService";
import { BrandService } from "../../services/BrandService";
import UsersServices from "../../services/UsersServices";

export default {
  components: {
    DataTable,
    Dropdown,
    InputText,
    Column,
    MultiSelect,
    Chip,
    Toolbar,
    Button,
    Checkbox,
    SplitButton,
    ToggleButton,
    AutoComplete,
  },

  props: ["values", "collapse", "price", "pricesRetails"],

  data() {
    return {
      paisSelectColumn: null,

      //Alert
      time: 2000,
    };
  },

  computed: {
    data() {
      if (this.values) {
        //this.values da props caso seja igual a 0 a tabela fica escondida e exibe um alert
        if (this.values.length == 0) {
          if (this.collapse) {
            this.collapse = "table-collapsed";
          }
        }
      }
    },
  },

  methods: {
    separator(valor) {
      var obj = valor;
      var newObj = valor.filter(function (este, i) {
        console.log(este, i)
        return valor.indexOf(este) === i;
      });

      return newObj;
    },

    selector(obj, field, price){
      console.log(obj, field, price.name);
      obj.map((item) =>{console.log(item.retail)})
      var newObj = obj.filter((item) => item.retail === field);

      console.log(newObj)

      if(!newObj[0]){
        return "---";
      }else{
        if(newObj[0][price.method] == 0){
          return "0,00"
        }else{
          return newObj[0][price.method];
        }
      }
    }
  },
};
</script>
