<template>
  <div>
    <div>
      <!-- @_____ == function callback -->
      <Query
        :paisList="paisList"
        :chipsCategoria="ChipsCategoria"
        :chipsRetail="ChipsRetail"
        @ShowPais="showPais"
        @ShowRetail="showRetail"
        @HideRetail="hideRetail"
        @ShowCategoria="showCategoria"
        @HideCategoria="hideCategoria"
        @ClickClear="clearList"
        @getObtener="getObtener"
        @areaInput="areaInput"
        :chipsArea="area"
        :chipsDivision="division"
        :chipsCategoria_Abm="categoria_abm"
        :btnLeftLabel="'Buscar'"
        :type="'comparativo'"
        :width="'158px'"
      ></Query>
    </div>
    <!-- @_____ == function callback -->
    <Table
      :values="productService"
      :collapse="collapse"
      :price="price"
      :pricesRetails="pricesRetails"
    ></Table>

    <div :class="'container-custom ' + spinner">
      <ProgressSpinner />
    </div>
  </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import Query from "./../components/Query/QueryCompHisto.vue";
import Table from "./../components/Tables/TableComparativa.vue";
import ProgressSpinner from "primevue/progressspinner";

//SERVICES
import { CountryService } from "./../services/Taxonomia/CountryService";
import { RetailsService } from "./../services/Taxonomia/RetailsService";
import { AreaService } from "./../services/AreaService";
import { CategoriaService } from "./../services/Taxonomia/CategoriaService";
import { CategoriaServiceABM } from "./../services/CategoriaService";
import { DivisionService } from "./../services/DivisionService";

import { OldBrandService } from "./../services/Taxonomia/OldBrandService";

import { ProductsService } from "./../services/Taxonomia/ProductsService";

export default {
  components: {
    Dropdown,
    MultiSelect,
    Button,
    Table,
    Query,
    ProgressSpinner,
  },
  data() {
    return {
      // DATA - API
      productService: [],
      loading: false,

      //CHIPS
      //Pais
      paisList: [],
      paisSelect: null,
      paisSelectColumn: null,

      paisQuery: null,
      price: null,

      //Retail
      ChipsRetail: [],

      selectedRetail: null,

      //Categoria
      ChipsCategoria: [],
      List: [],

      selectedCategoria: null,

      pricesRetails: null,
      //List abm
      area: [],
      division: [],
      categoria_abm: [],

      widthTable: "auto",
      collapse: "table-collapsed",
      spinner: "spinner-not",

      //Alert
      time: 2000,

      request: false,
    };
  },

  created() {
    this.loadCountry(); //Carrega todos os paises para os inputs
  },

  methods: {
    //TABLE
    loadCountry() {
      AreaService.getAll() //Carrega todos as areas para os inputs
        .then((result) => {
          this.area = result.data.data;
        })
        .catch((err) => {});
      CountryService.getAll() //Carrega todos os paises para os inputs
        .then((result) => {
          result.data = result.data.map((item) => {
            item.id = item.name;
            item.name_backup = item.name;

            item.name = item.name + " | " + item.country;
            this.paisList.push(item);
          });
        })
        .catch((err) => {});
    },

    //QUERY
    showPais(value) {
      if (value != null) {
        this.selectedRetail = null; //Quando seleciona um pais todos os outros campos selecionados devem ser apagados
        this.selectedCategoria = null;
        this.ChipsBrand = [];
        this.ChipsRetail = [];
        this.ChipsCategoria = [];

        this.paisSelect = value;
        RetailsService.getPerCountry(this.paisSelect.country) //Request retails
          .then((result) => {
            this.ChipsRetail = result.data.map((item) => {
              let retailUC = item.retail.toUpperCase();
              item.name = retailUC + " | " + this.paisSelect.country;
              item.id = this.paisSelect.country;
              return item;
            });
          })
          .catch((err) => {});
      }
    },

    showRetail(val) {
      if (this.paisSelect == null) {
        this.$store.dispatch("alert_error", {
          message: "Seleccione el país primero",
          time: this.time, //Padrao 2000
        });
      } else if (this.paisSelect != null) {
        if (this.paisSelect.length == 0) {
          this.$store.dispatch("alert_error", {
            message: "Seleccione el país primero",
            time: this.time, //Padrao 2000
          });
        }
      }

      this.request = true;
    },

    hideRetail(val) {
      if (val != null) {
        if (val == this.selectedRetail) {
          //caso os valor novo seja igual ao antigo somente nao faz a request
          this.selectedRetail = val;
        } else {
          this.selectedRetail = val;

          var retail = [];

          this.selectedRetail.map((item) => {
            //Trata os valores de selectedRetail para o back-end aceitar
            retail.push(item.retail);
          });
          this.ChipsCategoria = []; //Clear chips categoria

          CategoriaService.getPerCountry(this.paisSelect.country, retail)
            .then((result) => {
              result.data.map((item) => {
                item.name = item.category; //item.name recebe categoria name para os chips aceitarem
                item.id = item.category; //item.id recebe categoria name para os chips aceitarem
                this.ChipsCategoria.push(item);
                this.request = false;
              });
            })
            .catch((err) => {});

          if (this.ChipsCategoria.length > 0) {
            this.$store.dispatch("alert_success_bottom", {
              //Alert
              message: "Cargas totales - " + this.ChipsCategoria.length,
              time: this.time, //Padrao 2000
            });
          }
        }
      }
    },

    showCategoria(val) {
      if (this.request == true) {
      } else {
        if (this.selectedRetail == null) {
          this.$store.dispatch("alert_error", {
            //Alert
            message: "Seleccione el retail primero",
            time: this.time, //Padrao 2000,
          });
        } else if (this.selectedRetail != null) {
          if (this.selectedRetail.length == 0) {
            this.$store.dispatch("alert_error", {
              //Alert
              message: "Seleccione el retail primero",
              time: 2000, //Padrao 2000,
            });
          }
        }
      }
    },

    hideCategoria(val) {
      this.selectedCategoria = val;
    },

    showBrand() {
      if (this.selectedCategoria == null) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione el categoria primero",
          time: this.time, //Padrao 2000
        });
      } else if (this.selectedCategoria != null) {
        if (this.selectedCategoria.length == 0) {
          this.$store.dispatch("alert_error", {
            //Alert
            message: "Seleccione el categoria primero",
            time: this.time, //Padrao 2000
          });
        }
      }
    },

    areaInput(data) {
      this.division = []; //Seta division (ABM) para vazio

      DivisionService.getAll()
        .then((result) => {
          this.division = result.data.data; //division (abm) recebe data do result e logo em seguida é filtrado pelo id da area selecionado
          this.division = this.division.filter(
            (divisions) => divisions.area_id == data.id
          );

          if (this.division.length == 0) {
            this.$store.dispatch("alert_error", {
              //Alert
              message: "No encontramos divisiones con esta área.",
              time: this.time, //Padrao 2000
            });
          } else {
            this.$store.dispatch("alert_success_bottom", {
              //Alert
              message:
                "Encontramos un total de " +
                this.division.length +
                " divisions.",
              time: this.time, //Padrao 2000
            });
          }
        })
        .catch((err) => {});
    },

    //QUERY BTN

    clearList() {
      //No tienes callback
    },

    loadings(boolean) {
      if (boolean == true) {
        this.collapse = "table-collapsed"; //Css propriedades
        this.spinner = "spinner-loading"; //Css propriedades
      } else {
        setTimeout(() => {
          this.collapse = "not-collapsed"; //Css propriedades
          this.spinner = "spinner-not"; //Css propriedades
        }, 1500);
      }
    },

    getObtener(value) {
      this.productService = [];
      this.widthTable = "auto"; //Css propriedades
      this.collapse = "table-collapsed"; //Css propriedades

      if (!value.country) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione el país primero",
          time: this.time, //Padrao 2000
        });
      } else if (!value.retail) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione las retail primero",
          time: this.time, //Padrao 2000
        });
      } else if (!value.category_abm) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione las categoría primero",
          time: this.time, //Padrao 2000
        });
      } else if (!value.price) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione un tipo de precio",
          time: this.time, //Padrao 2000
        });
      } else {
        this.price = value.price;
        console.log(this.price);

        var date = value.date;
        date = date.toISOString();
        date = date.split("T");
        date = date[0];

        var country = value.country;

        if (value.retail != null) {
          var retails = value.retail.map((item) => {
            return item.retail;
          });
        } else {
          var retails = [];
        }

        var categories = value.category_abm.id;

        if (value.brand != null) {
          var brands = value.brand.map((item) => {
            return item.id;
          });
        } else {
          var brands = [];
        }

        var obj = {
          date: date,
          country: country,
          retails: retails,
          categories: categories,
          brands: brands,
        };

        ProductsService.comparative(obj)
          .then((result) => {
            var produtos = [];
            var varejos = [];

            result.data.data.map((item) => {
              var id = item.id;

              produtos.push(item);

              item.products.map((item) => {
                if (this.price.id == 1) {
                  var obj = {
                    name: this.price.name,
                    val: item.amount,
                    id: id,
                    varejo: item.retail,
                  };

                  varejos.push(item.retail)
                } else if (this.price.id == 2) {
                  var obj = {
                    name: this.price.name,
                    val: item.amount_discount,
                    id: id,
                    varejo: item.retail,
                  };

                  varejos.push(item.retail)
                } else if (this.price.id == 3) {
                  var obj = {
                    name: this.price.name,
                    val: item.amount_extra,
                    id: id,
                    varejo: item.retail,
                  };

                  varejos.push(item.retail)
                }
              });
            });

            console.log(varejos);
            this.pricesRetails = varejos;

            this.productService = produtos;

            this.loading = false; //Css propriedades
            this.loadings(false);
          })
          .catch((err) => {});
      }
    },
  },
};
</script>
<style lang=""></style>
